<template>
    <div class="wrapper">
        <!-- Header -->
        <Header/>
        <!-- End Header -->
        <div class="section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <div class="contact-info">
                            <h2 class="fs-38 fw-bolder pb-4 text__black">
                                Contact Info.
                            </h2>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex mb-40">
                                        <div class="circular-info-icon">
                                            <i class="bi bi-envelope"></i>
                                        </div>
                                        <div class="circular-info-content align-self-end">
                                            <p>
                                                <a :href="'mailto:' + businessEmail" class="text__gradient">
                                                    {{ businessEmail }}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex mb-40">
                                        <div class="circular-info-icon">
                                            <i class="bi bi-geo-alt"></i>
                                        </div>
                                        <div class="circular-info-content align-self-end">
                                            <p>
                                                #7054 246 Stewart Green SW <br>
                                                Calgary, AB,<br>
                                                T3H 3C8 Canada.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="d-flex mb-40">
                                        <div class="circular-info-icon">
                                            <i class="bi bi-telephone"></i>
                                        </div>
                                        <div class="circular-info-content align-self-end">
                                            <p>+012 345 678 102</p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-7">
                        <div class="contact-form-wrap">
                            <h2 class="fs-38 fw-bolder pb-4 text__black">
                                Talk to a <span class="text__gradient">Human</span>.
                            </h2>
                            <p>
                                Whether it is a suggestion, feedback, or complaints, our representative is here to
                                listen. Leave us a message.
                            </p>
                            <div class="contact-from-wrapper section-space--mt_30">
                                <form id="contactForm">
                                    <div class="contact-page-form">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-input">
                                                    <input placeholder="Your Name/ Organization Name *"
                                                           type="text" v-model="form.name" required>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-input">
                                                    <input placeholder="Your Email/ Company Email *"
                                                           type="email" v-model="form.email" required>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-input">
                                                    <textarea rows="5" placeholder="Message *"
                                                              v-model="form.message"
                                                              required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <VueRecaptcha ref="recaptcha" @verify="submit"
                                                      :sitekey="siteKey"/>

                                        <div class="comment-submit-btn">
                                            <button class="btn mt-4" type="button" :class="{'btn__gradient': !disabled}"
                                                    @click="sendEmail" :disabled="disabled">
                                                {{ btnText }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <Footer/>
        <!-- End Footer -->
    </div>
</template>
<script>
import Header from "@/components/elements/Header";
import Footer from "@/components/elements/Footer";
import axios from "axios";
import { VueRecaptcha } from 'vue-recaptcha';
import { emailJSCredentials, siteKey, appName, businessEmail } from "@/utils/variables";

export default {
    name: "Contact",
    components: {Footer, Header, VueRecaptcha},
    data() {
        return {
            siteKey,
            businessEmail,
            disabled: false,
            btnText: 'Send Message',
            gResponse: '',
            form: {
                name: '',
                email: '',
                message: ''
            }
        }
    },
    methods: {
        submit (results) {
            this.gResponse = results;
        },
        async sendEmail() {
            this.btnText = 'Sending...'
            this.disabled = true
            let data = {
                service_id: emailJSCredentials.SERVICE_ID,
                template_id: emailJSCredentials.TEMPLATE_ID,
                user_id: emailJSCredentials.PUBLIC_KEY,
                template_params: {
                    'from_name': this.form.name,
                    'from_email': this.form.email,
                    'to_name': appName,
                    'message': this.form.message,
                    'g-recaptcha-response': this.gResponse
                }
            };

            await axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
                .then((res) => {
                    if (res.data === 'OK') {
                        alert('Message sent successfully.')
                    }
                })
                .catch(() => {
                    alert('Message not sent successfully.')
                })
                .finally(() => {
                    this.form.name = '';
                    this.form.email = '';
                    this.form.message = '';
                    this.btnText = 'Send Message'
                    this.disabled = false
                })
        }
    }
}

</script>
<style scoped>

</style>
